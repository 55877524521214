import React from 'react';
import PropTypes from 'prop-types';


const Logo = ({ width, height, black }) => {
    return (
        <img
            src={black ? require('./../../assets/images/blackpng.png') : require('./../../assets/images/whitepng.png')}
            //converter string width para porcentagem
            width={width + '%'}

        />
    );
};

Logo.propTypes = {
    width: PropTypes.number,
    black: PropTypes.bool
};
Logo.defaultProps = {
    width: '100%',
    black: false
};


export default Logo;

