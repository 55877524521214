import React, { useState } from 'react';
import Modal from '@mui/material/Modal';

const Fechamento = () => {


    function navigateToHome() {
        window.location.href = '/'
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', height: '100%', width: '90%' }}>
                <div style={{ display: 'flex', marginTop: '0.5rem', flexDirection: 'column', justifyContent: 'center', padding: '0.8rem 1rem', background: '#5B5B5B', borderRadius: '0.6rem', width: '90%' }}>
                    <span style={{ fontWeight: '700', lineHeight: '1.3rem', fontSize: '1.2rem' }}>Nome:</span>
                    <span style={{ fontWeight: '700', lineHeight: '1.3rem', fontSize: '1.2rem' }}>CPF:</span>
                    <span style={{ fontWeight: '700', lineHeight: '1.3rem', fontSize: '1.2rem' }}>Local:</span>
                    <span style={{ fontWeight: '700', lineHeight: '1.3rem', fontSize: '1.2rem' }}>Data:</span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '0px', width: '100%', marginTop: '0.5rem' }}>
                    <label style={{ fontWeight: '700', fontSize: '1.2rem', textAlign: 'center' }}>Valor da Comanda:</label>
                    <h1 style={{ width: '60%', marginTop: '0.4rem', padding: '0.5rem ', minHeight: '30px', background: '#FFCC06', borderRadius: '1.5rem', color: 'black', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '900', fontSize: '1.6rem', textAlign: 'center' }}>R$ 500,00</h1>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <label style={{ fontWeight: '700', fontSize: '1.2rem', textAlign: 'center' }}>Pagar com PIX</label>
                    <img src={require('./../../assets/images/qrcodeExemplo.png')} alt="pix" style={{ marginTop: '8px', maxWidth: '70%', background: 'white' }} />
                </div>


                <button style={{ marginTop: '8px', marginBottom: '8px', width: '70%', padding: '0.5rem ', background: '#FFCC06', borderRadius: '1.5rem', color: 'black', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '900', fontSize: '1.6rem', textAlign: 'center' }}>Copiar PIX</button>

            </div>
        </>
    );
}

export default Fechamento;