import React, { useState } from 'react';
import FooterDefault from '../../components/footerDefault';
import Logo from '../../components/logo';
import { BsFillBackspaceFill } from "react-icons/bs";
import './calculadora.css'
import masks from '../../utils/masks';



const Home = () => {
    const [number, setNumber] = useState('');

    function inputNumber(e) {
        setNumber(number + e.target.value)
        if (number.length > 10) {
            setNumber(number)
        }
    }

    function backspace() {
        setNumber(number.slice(0, -1))
    }

    function clear() {
        setNumber('')
    }

    function navigateToTabs() {
        window.location.href = `/Tabs?number=${number}`
    }

    const container = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    }

    return (
        <div style={container}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', height: '100%', maxWidth: '90%' }}>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                    <Logo black={false} width={50} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '1rem', background: '#5B5B5B', borderRadius: '0.6rem', height: '5%', width: '90%' }}>
                    <span style={{ fontWeight: '700', lineHeight: '1rem', fontSize: '1.2rem' }}>Evento:</span>
                    <span style={{ fontWeight: '700', lineHeight: '2rem', fontSize: '1.2rem' }}>Data:</span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0px', width: '100%', }}>
                    <label style={{ fontWeight: '700', fontSize: '1.2rem', textAlign: 'center' }}>Digite o seu CPF</label>
                    <h1 style={{ marginTop: '0.7rem', padding: '0.5rem 1rem', minHeight: '38px', background: '#5B5B5B', borderRadius: '0.7rem', color: 'white', fontFamily: 'Roboto', fontSize: '2rem', textAlign: 'center' }}>{masks.cpf(number)}</h1>
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '383px' }}>
                    <button className='number' value={1} onClick={inputNumber}>1</button>
                    <button className='number' value={2} onClick={inputNumber}>2</button>
                    <button className='number' value={3} onClick={inputNumber}>3</button>
                    <button className='number' value={4} onClick={inputNumber}>4</button>
                    <button className='number' value={5} onClick={inputNumber}>5</button>
                    <button className='number' value={6} onClick={inputNumber}>6</button>
                    <button className='number' value={7} onClick={inputNumber}>7</button>
                    <button className='number' value={8} onClick={inputNumber}>8</button>
                    <button className='number' value={9} onClick={inputNumber}>9</button>
                    <button className='function' onClick={clear}>Limpar</button>
                    <button className='number' value={0} onClick={inputNumber}>0</button>
                    <button className='function' onClick={backspace}>
                        <BsFillBackspaceFill size={35} />
                    </button>
                    <button className='action' onClick={navigateToTabs}>PESQUISAR</button>
                </div>

            </div>

            <FooterDefault />

        </div>

    )
}

export default Home;
