import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import Home from './pages/home';
import Tabs from './pages/tabs';
import Consulta from './pages/consulta';
import Fechamento from './pages/fechamento';

function RoutesApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home/*" element={<Home />} />
                <Route path="/tabs/*" element={<Tabs />} />
                <Route path="/consulta/*" element={<Consulta />} />
                <Route path="/fechamento/*" element={<Fechamento />} />
            </Routes>
        </BrowserRouter>
    );

}

export default RoutesApp;