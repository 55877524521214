import React from "react";
import "./Footer.css";
import Logo from "../logo";

export default function () {
    return (
        <div className="footer">
            <Logo width={35} black={true} />
        </div>
    );
}

