import React, { useState } from 'react';
import masks from '../../utils/masks';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

const Consulta = () => {
    const [value, setValue] = React.useState(0);
    const [cpf, setCpf] = useState('');
    const url = window.location.href;
    const cpfUrl = url.slice(-11)
    console.log(cpfUrl)

    const data = [
        {
            "id": 1,
            "name": "Batata Frita",
            "price": '5000',
            "quantity": 2,
        },
        {
            "id": 2,
            "name": "Pizza",
            "price": '10000',
            "quantity": 2,
        },
        {
            "id": 3,
            "name": "Coca-Cola",
            "price": '3000',
            "quantity": 2,
        },
        {
            "id": 4,
            "name": "Suco de Laranja",
            "price": '3000',
            "quantity": 2,
        },
        {
            "id": 5,
            "name": "Suco de Uva",
            "price": '3000',
            "quantity": 2,
        },
        {
            "id": 6,
            "name": "Suco de Abacaxi",
            "price": '3000',
            "quantity": 2,
        },
        {
            "id": 7,
            "name": "Suco de Maracujá",
            "price": '3000',
            "quantity": 2,
        },

    ]


    function navigateToHome() {
        window.location.href = '/'
    }


    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%', width: '90%' }}>
                <div style={{ display: 'flex', marginTop: '0.5rem', flexDirection: 'column', justifyContent: 'center', padding: '0.8rem 1rem', background: '#5B5B5B', borderRadius: '0.6rem', width: '90%' }}>
                    <span style={{ fontWeight: '700', lineHeight: '1.3rem', fontSize: '1.2rem' }}>Nome:</span>
                    <span style={{ fontWeight: '700', lineHeight: '1.3rem', fontSize: '1.2rem' }}>CPF:</span>
                    <span style={{ fontWeight: '700', lineHeight: '1.3rem', fontSize: '1.2rem' }}>Local:</span>
                    <span style={{ fontWeight: '700', lineHeight: '1.3rem', fontSize: '1.2rem' }}>Data:</span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '0px', width: '100%', marginTop: '0.5rem' }}>
                    <label style={{ fontWeight: '700', fontSize: '1.2rem', textAlign: 'center' }}>Valor da Comanda:</label>
                    <h1 style={{ width: '50%', marginTop: '0.4rem', padding: '0.5rem 1rem', minHeight: '30px', background: '#FFCC06', borderRadius: '1.5rem', color: 'black', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '900', fontSize: '1.6rem', textAlign: 'center' }}>R$ 500,00</h1>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '1rem', background: '#5B5B5B', borderRadius: '0.6rem', minHeight: '23%', width: '90%' }}>
                    <TableContainer sx={{ maxHeight: 200, }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableBody>
                                {data.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            <TableCell style={{ color: 'white', fontWeight: '400', fontSize: '0.9rem' }}>
                                                {row.quantity}X
                                            </TableCell>
                                            <TableCell style={{ color: 'white', fontWeight: '400', fontSize: '0.9rem' }}>
                                                {row.name}
                                            </TableCell>

                                            <TableCell style={{ color: 'white', fontWeight: '400', fontSize: '0.9rem' }}>
                                                R${masks.money(row.price)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '0.5rem' }}>
                        <span style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '900', fontSize: '1.2rem' }}>Valor Total:</span>
                        <span style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '900', fontSize: '1.2rem' }}>R$ 500,00</span>

                    </div>

                </div>
            </div>
        </>
    );
}

export default Consulta;