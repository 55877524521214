import React, { useState } from 'react';
import { AiOutlineRollback } from "react-icons/ai";
import Logo from '../../components/logo';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { CgProfile } from "react-icons/cg";
import { GiCoins } from "react-icons/gi";
import Consulta from '../consulta';
import Fechamento from '../fechamento';


const Tabs = () => {
    const [value, setValue] = React.useState(0);
    const [activeTab, setActiveTab] = useState('consulta');

    function navigateToHome() {
        window.location.href = '/'
    }

    const container = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    }

    return (
        <div style={container}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', maxWidth: '90%' }}>
                <div style={{ alignSelf: 'flex-start', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '0.5rem' }}>
                    <AiOutlineRollback size={35} onClick={navigateToHome} style={{ cursor: 'pointer' }} />
                    <Logo black={false} width={50} />
                    <div></div>
                </div>
                {activeTab === 'consulta' ? <Consulta /> : <Fechamento />}
            </div>
            <BottomNavigation
                style={{ background: '#FFCC06', padding: '0.5rem 0', width: '100vw' }}
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    setActiveTab(newValue)
                }}>
                <BottomNavigationAction
                    style={{ padding: '5px 50px', webkitFlex: '0', marginRight: '40px', display: 'flex', borderRadius: '0.7rem', color: activeTab === 'consulta' ? 'white' : '#000', background: activeTab === 'consulta' ? '#616161' : '#FFCC06' }}
                    label="CONSULTA" value="consulta" icon={<CgProfile size={35} />} />
                <BottomNavigationAction
                    style={{ padding: '5px 50px', webkitFlex: '0', marginLeft: '40px', borderRadius: '0.7rem', color: activeTab === 'fechamento' ? 'white' : '#000', background: activeTab === 'fechamento' ? '#616161' : '#FFCC06' }}
                    label="FINALIZAR" value="fechamento" icon={<GiCoins size={35} />} />
            </BottomNavigation>
        </div>
    )
}

export default Tabs