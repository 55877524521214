import RoutesApp from './routers';

function App() {

  return (
    <RoutesApp />
  );
}

export default App;
